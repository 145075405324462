import i18n from '../../managers/i18n'

i18n.register(
  'zh-Hans',
  {
    ok: '确定',
    cancel: '取消'
  },
  {
    ns: 'popover'
  }
)
