import i18n from '../../managers/i18n'

i18n.register(
  'zh-Hans',
  {
    close: '关闭',
    ok: '确定',
    cancel: '取消'
  },
  {
    ns: 'dialog'
  }
)
