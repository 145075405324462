import i18n from '../../managers/i18n'

i18n.register(
  'zh-Hans',
  {
    loading: '加载中……'
  },
  {
    ns: 'loading'
  }
)
