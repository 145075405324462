import i18n from '../../managers/i18n'

i18n.register(
  'zh-Hans',
  {
    increase: '增加 {value}',
    decrease: '减少 {value}'
  },
  {
    ns: 'numberinput'
  }
)
